.header{
    display:flex;
    justify-content: space-between;
}

.logo{
    width: 10rem;
    height: 3rem;
}

.header-menu{
    /* removes bulletr */
    list-style: none;
    display: flex;
    gap: 2rem;
    color: aliceblue;
}

.header-menu>li{
    cursor: pointer;
}

.header-menu>li:hover{
    color:var(--orange);
    cursor: pointer;
}

