.program{
    display:flex;
    
    flex-direction: column;
    gap:3rem;
    padding: 0 7rem;

}

.programs-header{

    display: flex;
    gap:6rem;
    font-weight: bold;
    text-transform: uppercase;
    color:white;
    font-size: 3rem;
    font-style: italic;
}

.program-catgories{
    
    margin-top: 3rem;
    display: flex;
    gap: 1rem;
}

.category{
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: gray;
    padding: 2rem;
    gap: 1rem;
    color:white;
    justify-content: space-between;
}

.category>:nth-child(1){
    width: 2rem;
    height: 2rem;
    fill:white;
}

.category>:nth-child(2){
    font-size: 2rem;
    font-weight: bold;
    font-style: italic;

}

.category>:nth-child(3){
    font-size: 0.9rem;
    line-height: 18px;

}

.join-now>img{
    position: absolute;
    width: 2rem;
    margin-top: 0.5rem;
    right:4rem
}

.join-now>span{
    font-size: 2rem;
    font-weight: bold;
}

.category:hover{
    background: var(--planCard);
    cursor: pointer;
}

