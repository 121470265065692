.testimonial{
    display: flex;
    gap:1rem;
    padding: 0 2rem;
}


.left-t{
    display: flex;
    flex: 1 1;
    gap:2rem;
    flex-direction: column;
    text-transform: uppercase;
    color: white;
}

.left-t>:nth-child(1){
    color: var(--orange);
    font-weight: bold;
}

.left-t>:nth-child(2){
    font-size: 3rem;
    font-weight: bold;
}

.left-t>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}

.left-t>:nth-child(4){
    text-transform: none;
    text-align: justify;
    letter-spacing: 1px;
    line-height: 40px;
}

.right-t{
   
    flex: 1 1;
    position: relative;
}

.right-t>img{
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 8rem;
    top: 2rem;
}

.right-t>:nth-child(1){
    position: absolute;
    width: 17rem;
    height: 20rem;
    border: 2px solid orange;
    background-color: transparent;
    right: 9rem;
    top: 0.6rem;
}

.right-t>:nth-child(2){
    position: absolute;
    width: 17rem;
    height: 20rem;
    background: var(--planCard);
    top:4rem;
    right:6rem;
}

.arrows{
    display: flex;
    position: absolute;
    bottom:1rem;
    left:6rem;
    gap: 0.5rem;
}
.arrows>img{
    width: 1.5rem;
    cursor: pointer;
    
}

