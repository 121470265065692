.Footer-container{
    position: relative;
}

.Footer-container>hr{
    border-radius: 1px solid var(--lightgray);
}

.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap:4rem;
    align-items: center ;
}


.social-links{
    display: flex;
    gap: 4rem;
}

.social-links>img{
    height: 2rem;
    width: 2rem;
    cursor: pointer;
}


.logo-f>img{
  width: 10rem;
}