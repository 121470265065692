.join{
  display: flex;
  padding: 0 2rem;
  gap:10rem;
  margin-top: 3rem;
}


.left-j{
    color: white;
    font-size: 3rem;
    font-weight: bold;
    position: relative;
}

.left-j>hr{
 position: absolute;
 width: 10.5rem;
 border: 2px solid var(--orange);
 border-radius: 20%;
 margin-top: -1rem;
}

.left-j>div{
    display: flex;
    gap: 1rem;
}


.right-j{
  
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    


}



.email-container{
    position: absolute;
    display:flex;
    align-items: center;
    height: 5rem;
    left:10rem;
    gap:2rem;
    background-color: gray;
    padding:0.1rem 2rem;
    border-radius: 5rem;

}

.email-container>input{
    background-color: transparent;
    outline: none;
    border:none;
    color:var(--lightgray);
}

::placeholder{
    color:var(--lightgray);
}

.btn-j{
    left:10rem;
    background: var(--orange);
    color: white;
    border-radius: 6rem;

}





