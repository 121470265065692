.hero{
    display : flex;
    justify-content : space-between;
}

.Left-h{
    padding: 2rem;
    padding-top: 1.5rem;
    /* space corevtion */
    flex: 3 ;
}

.Right-h{
    flex: 1;
    position: relative;
    background-color: var(--orange);
}

.the-best-ad{
    margin-top: 7rem;
    background-color: #363d42;
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 13px;
    text-transform: uppercase;
    color: white;
    position: relative;
    display: flex;
    align-items: center;
}

.the-best-ad>span{
    z-index: 2;
}

.the-best-ad>div{
    position:absolute;
    background-color: var(--orange);
    width: 5.4rem;
    height: 80%;
    left:8px;
    border-radius: 3rem;
    z-index: 1;
}


.hero-text{
    display: flex;
    margin-top: 3rem;
    flex-direction: column;
    gap:1.5rem;
    font-size: 4.5rem;
    text-transform: uppercase;
    font-weight: bold;
    color:white;
    text-overflow: inherit;
}

.hero-text>div:nth-of-type(3){
    font-size: 1rem;
    margin-top: 1rem;
    font-weight: 300;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
}




.figures{
    margin-top: 2rem;
    display: flex;
    gap: 2rem;

}

.figures>div{
    display: flex;
    flex-direction: column;
}

.figures>div>span:nth-of-type(1){
    color: wheat;
    font-size: 2rem;
}


.figures>div>span:nth-of-type(2){
    color: var(--gray);
    text-transform: uppercase;
}

.hero-buttons{
    display:flex;
    gap:1rem;
    font-weight: normal;
    margin-top: 2rem;
    
}

.hero-buttons>:nth-child(1){
    background-color: orange;
    width: 8rem;
    color: white;
}

.hero-buttons>:nth-child(2){
    background-color: transparent;
    width: 8rem;
    color: white;
    border: 2px solid orange;
}

.Right-h>.btn{
    position: absolute;
    right: 3rem;
    top:2rem;
    color:black;
    border-radius: 5rem;
}

.heart-rate{
    display:flex;
    flex-direction: column;
    gap:1rem;
    background-color: var(--darkGrey);
    width: fit-content;
    padding: 1rem;
    align-items: start;
    position: absolute;
    right:4rem;
    top:7rem;
}


.heart-rate>:nth-child(2){
    color:var(--gray);
}

.heart-rate>:nth-child(3){
    color:white;
    font-size:1.5rem;
}


.hero-image{
    position: absolute;
    top: 10rem;
    right:8rem;
    width: 23rem;
    z-index: 2;
}

.hero-image-back{
    position: absolute;
    top: 4rem;
    right:20rem;
    z-index: 1;
    width: 15rem;
    
}

.calories{
    display:flex;
    gap:1rem;
    background-color: var(--caloryCard);
    border-radius: 5px;
    padding: 1rem;
    width:13rem;
    position: absolute;
    top:32rem;
    right:26rem;
}

.calories>img{
    width:3rem
}

.calories>div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.calories>div>:nth-child(1){
    color: var(--gray);
}

.calories>div>:nth-child(2){
    color: white;
    font-size: 1.5rem;
}

.blur{
    background: rgba(253, 120, 43, 0.69);
    position: absolute;
    border-radius: 50%;
    filter:blur(212px);
    z-index: -100;
  }

 











